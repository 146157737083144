import React, { useState, useEffect } from "react";
import "../pages/Products.css";
import {
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  Row,
  Col,
} from "reactstrap";
import { NavDropdown } from "react-bootstrap";
import classnames from "classnames";
import AOS from "aos";
import "aos/dist/aos.css";
import Tabs from "../components/Tabs";

// import { Row, Col, Button } from "react-bootstrap";

function Products1({ ProductData, Akey, Tkey }) {
  // const Akey ={Akey};

  const [isMobile, setIsMobile] = useState(false);

  //choose the screen size
  const handleResize = () => {
    if (window.innerWidth < 720) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  };

  console.log(Akey);

  const [activeTab, setActiveTab] = useState(Akey);

  const toggle = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  const [activeTabMob, setActiveTabMob] = useState("11");

  const toggleMob = (tab) => {
    if (activeTabMob !== tab) setActiveTabMob(tab);
  };
  //-----------------------------------------------------


  // create an event listener
  useEffect(() => {
    handleResize();
  });
  //-------------------------------------------------------

  AOS.init();
  // ---------------------------------------------------------------------------
  const products = ProductData[0].data;
  return (
    <div>
      {/* {console.log({isMobile})} */}
      <div className="product-tabs ">
        <div className="">
          {(() => {
            if (isMobile) {
              return (
                <Row className="">
                  <Col sm={3}>
                    <Nav tabs vertical="sm" className="d-block text-dark">
                      {products.map((a) => (
                        <NavDropdown title={a.tittle} id="nav-dropdown" 
                        className={[
                          "navCustom fw-medium w-100 text-dark",
                          classnames({ active: activeTab === a.index }),
                        ].join(" ")} >
                          {a.subproduct.map((x) => (
                            <NavDropdown.Item
                              eventKey=""
                              className={[
                                "navCustom fw-medium d-block w-100",
                                classnames({ active: activeTabMob === a.index+x.index }),
                              ].join(" ")}
                              onClick={() => {
                                toggleMob(a.index+x.index);
                              }}
                            >
                              {" "}
                              {x.tittle}
                            </NavDropdown.Item>
                          ))}
                        </NavDropdown>
                      ))}
                    </Nav>
                  </Col>
                  <Col sm={9}>
                    <TabContent activeTab={activeTabMob}>
                      {products.map((p) =>
                        p.subproduct.map((x) => (
                          <TabPane tabId={p.index+x.index} className="p-5">
                            <Row className="w-auto">
                              <Col>
                                <h4 className="mb-3">{x.tittle}</h4>
                                <p className="tabContent fw-normal">{x.p1}</p>
                                <p className="tabContent fw-normal">{x.p2}</p>
                                <p className="tabContent fw-normal">{x.p3}</p>
                              </Col>
                            </Row>
                          </TabPane>
                        ))
                      )}
                    </TabContent>
                  </Col>
                </Row>
              );
            }

            return (
              <Row className="">
                <Col sm={3}>
                  <Nav tabs vertical="sm">
                    {products.map((a) => (
                      <NavItem>
                        <NavLink
                          className={[
                            "navCustom fw-medium ",
                            classnames({ active: activeTab === a.index }),
                          ].join(" ")}
                          onClick={() => {
                            toggle(a.index);
                          }}
                        >
                          {a.tittle}
                        </NavLink>
                      </NavItem>
                    ))}
                  </Nav>
                </Col>
                <Col sm={9}>
                  <TabContent activeTab={activeTab}>
                    {products.map((a) => (
                      <TabPane
                        tabId={a.index}
                        className="shadow rounded bg-white"
                      >
                        <Row>
                          <Tabs props={a.subproduct} Tkey={Tkey} />
                        </Row>
                      </TabPane>
                    ))}
                  </TabContent>
                </Col>
              </Row>
            );
          })()}
        </div>
      </div>

      {/* <hr class="container border-3 border-top " /> */}
    </div>
  );
}

export default Products1;
