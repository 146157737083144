import konvoid from "../utils/img/manufacture/konvoid.jpg";
import twin1 from "../utils/img/manufacture/twin1.jpg";
import NoImg from "../utils/img/manufacture/NOIMG.jpg";
import CCGB from "../utils/img/manufacture/CCGB.JPG";
import SSPT1 from "../utils/img/manufacture/SSPT1.JPG";
import SSPT2 from "../utils/img/manufacture/SSPT2.JPG";
import SSPT3 from "../utils/img/manufacture/SSPT3.JPG";
import WOODCUTTER from "../utils/img/manufacture/Woodruff Cutter.JPG";
import WMW from "../utils/img/manufacture/WMW BLADE.JPG";
import SPI from "../utils/img/manufacture/spiromatic.jpg";
import KUR from "../utils/img/manufacture/kurvex.jpg";

const products = [
  {
    heading: "Cutting Tools",
    productClass:"product-page",
    data: [
      {
        tittle: "Straight Bevel Gears",
        index: "1",
        subproduct: [
          {
            index: "1",
            img: [{ img: konvoid }, { img: WMW }],
            tittle: "WMW / Konvoid blades",
            p1: "Konvoid blades are used for straight bevel gear generators in WMW, Klingelnberg, and/or Russian machines across multiple nose-widths and pressure angles. Typically the sets are either 44 blades (22 + 22), 56 blades (28 + 28) or 72 blades (63 + 36).",
            p2: "Our blades are predominantly crafted from premium high-speed steel grades M2, M35, and M42.",
            p3: "We also provide multiple coating options like TiN, AlTiN (TiAlN), and ALCRONA as per requirements.",
          },
          {
            index: "2",
            img: [{ img: twin1 }],

            tittle: "Twin Generating blades",
            p1: "Designed for compatibility with straight bevel generator machines, we manufacture Twin Generating blades as per requirements, with High Speed Steel M2 or M35 grades, from modules ranging from 0.3 to 25. ",
            p2: "We typically provide ALCRONA coating, and can adjust it as per requirements.  ",
            p3: "",
          },
        ],
      },
      {
        tittle: "Spiral Bevel Gears",
        index: "2",
        subproduct: [
          {
            index: "1",
            img: [{ img: NoImg }],
            tittle: "Gleason",
            p1: "Used for generating spiral bevel gears, our Gleason Cutting tools are crafted from premium HSS M2, M35 materials. ",
            p2: "We manufacture crown and pinion blade sets (IB + OB) for roughing and finishing across cutter body diameters, ranging from 3.5” to 18. ",
            p3: "",
          },
          {
            index: "2",
            img: [{ img: CCGB }],
            tittle: "Curvic Coupling Blades",
            p1: "We manufacture Curvic Coupling blades for 6” / 7.5” and 9” diameter cutters. These are created with premium HSS M2 and M35 materials.",
            p2: "Our setting of the blades on the cutter body acheives a super-high precision and within the minutest of tolerances required.",
            p3: " Coating is required as needed.",
          },
          {
            index: "3",
            img: [{ img: KUR }],
            tittle: "Kurvex (old/new)",
            p1: "Our Kurvex blades, available with and without toprem options, are meticulously crafted for optimal performance in spiral bevel gear machines. Constructed from premium materials, these blades guarantee efficiency and durability, meeting stringent quality standards. ",
            p2: " We are proficient in manufacturing old or new formats of Kurvex cutter blades. ",
            p3: "Coating is required as needed.",
          },
          {
            index: "4",
            img: [{ img: SPI }],
            tittle: "Spiromatic blades",
            p1: "We manufacture spiromatic cutting blades, specifically Oerlikon cutting tools, in various standard sizes - both for roughing and finishing. ",
            p2: "The tools are made in premium HSS M2, M35,or M42 material. TiN, AlTiN / TiAlN, or ALCRONA coating is provided as per requirements.",
            p3: "",
          },
        ],
      },
      {
        tittle: "Special HSS/Carbide Single Point cutting tools",
        index: "3",
        subproduct: [
          {
            index: "1",
            img: [{ img: SSPT1 }, { img: SSPT2 }, { img: SSPT3 }],
            tittle: "Special HSS/Carbide Single Point cutting tools",
            p1: "We manufacture single point cutting tools for our clients that are custom-made to the client specifications in HSS M2, M35, or M42 materials, with carbide tips as needed. ",
            p2: "These are also coated with AlTiN (TiAlN), and ALCRONA coating as per requirements. ",
            p3: "",
          },
        ],
      },
      {
        tittle: "Woodruff Cutters",
        index: "4",
        subproduct: [
          {
            img: [{ img: WOODCUTTER }],
            index: "1",
            tittle: "Woodruff cutters",
            p1: "Woodruff Cutters meticulously crafted to meet the demands of precision machining in various industries.",
            p2: " Our Woodruff cutters are designed for slotting, key-seat milling, and other specialized applications, offering exceptional performance and durability",
            p3: "",
          },
        ],
      },
      {
        tittle: "Allied products",
        index: "5",
        subproduct: [
          {
            img: [],
            index: "1",
            tittle: "Allied products",
            p1: "We create products used with gear cutting tools like cutter body bolts e.g., with star Allen key heads, or with ground threads for accuracy. Please reach out to us for all your needs",  },
          
        ],
      },
    ],
  },
];
export default products;
