import React, { useState } from "react";
import "../pages/Products.css";
import AOS from "aos";
import "aos/dist/aos.css";
import Footer from "../components/Footer";

function ProductsCust() {
  // const Akey ={Akey};

  AOS.init();
  // ---------------------------------------------------------------------------
  // const productClass = products1[0].productClass;

  return (
    <div className="home-page product-page1">
    <header className="mt-5">
      <div className=" h-100 d-flex align-items-center justify-content-center flex-column text-center">
        <h3 className="text-light text-wrap mt-5">Custom-made Tool Room Products</h3>
        <a href="/Cutting Tools" className="fs-3vw fw-medium text-white text-nowrap product-header-link" >Cutting Tools</a>

      </div>
    </header>
    <div className="container my-5 text-wrap text-sm-left" data-aos="fade-up">
<div className="p-5">
<p className="fs-3vw fw-normal">We manufacture multiple high-accuracy and precision needed critical tool-room requirements. We have expertise in bore grinding fixtures (typically used for crown wheels), index plates, and spacers / parallels.</p>
<p className="fs-3vw fw-normal"> We make all types of gauges viz., relation gauges, receiver gauges, hole position gauges, etc. For PCD ball pinsacross any size, we use carbide balls of 2.5 microns sphericity for the tips, to achieve high accuracy and we maintain the height of the pins from collar to the ball within +/- 5 microns.</p>
            <p className="fs-3vw fw-normal"> Please use the request form to provide us with your requirements in detail and we will get back to you.</p>
         
</div>
</div>
      <Footer/>

    </div>
  );
}

export default ProductsCust;
