import React from "react";
import "./About.css";
import Footer from "../components/Footer";
import AOS from "aos";
import "aos/dist/aos.css";
import { Row } from "react-bootstrap";
import { Card, CardBody, CardGroup, CardImg ,Image} from "react-bootstrap";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faQuoteLeft
} from "@fortawesome/free-solid-svg-icons";
import Person4 from "../utils/img/person43.jpg";
import Person3 from "../utils/img/person33.jpg";
import Person2 from "../utils/img/person23.jpg";
import Person1 from "../utils/img/person13.jpg";
import BG from "../utils/img/clientLogo/BG.png";
import Eicher from "../utils/img/clientLogo/Eicher.png";
import tatamotors from "../utils/img/clientLogo/tatamotors.png";
import BF from "../utils/img/clientLogo/BF.png";
import AAM from "../utils/img/clientLogo/AAM_Logo.png";
import AAL from "../utils/img/clientLogo/AAL.png";
import GP from "../utils/img/clientLogo/gp.png";
import TK from "../utils/img/clientLogo/TK.png";
import GRZ from "../utils/img/clientLogo/GRZ.png";
import PBG from "../utils/img/clientLogo/PBG.png";
import emson from "../utils/img/clientLogo/emson_logo.png";
import NRB from "../utils/img/clientLogo/nrblogo.png";
import CAR from "../utils/img/clientLogo/Carraro.png";
import ECH from "../utils/img/clientLogo/ECH.png";

function About() {
  AOS.init();

  return (
    <div className="about-page home-page ">
      <header className="mt-5">
        <div className=" h-100 d-flex align-items-center justify-content-center">
          <h1 className="text-light">About Us</h1>
        </div>
      </header>

      <div className="container my-5 text-wrap text-sm-left" data-aos="fade-up">
        <Row className="mb-4 p-3">
          <h4>
            <span> Welcome to </span>
          </h4>
          <h3 className="mb-4">
            <span>Worth Engineering</span>
          </h3>
          <div class="divider divider-1 mb-5"></div>
          <p className="fs-3vw fw-normal">
            Your trusted partner in precision tool engineering. Since our
            inception in <strong>1987</strong>, we have been at the forefront of
            innovation, delivering high-quality solutions to meet the evolving
            needs of the industry.
          </p>
          <p className="fs-3vw fw-normal">
            At <strong>Worth Engineering</strong>, we pride ourselves on being a
            fully equipped Tool Room with
            <strong>&nbsp;ISO 9001-2000 Certification</strong>, ensuring that
            our processes and products adhere to the highest standards of
            quality and reliability.
          </p>{" "}
        </Row>
        <Row className="mb-4 p-3" data-aos="fade-up">
          <p className="mb-5 fs-4 fw-medium">
            At <>Worth Engineering</>, our mission is simple :
          </p>
          <blockquote class="blockquote blockquote-custom SecManufactureCard p-5 shadow rounded">
          <div class="blockquote-custom-icon bg-Worth-color-1 shadow-sm"><FontAwesomeIcon icon={faQuoteLeft}  color="#FFFFFF"  />
          </div>
            <p className="fw-medium fs-3vw ">
              We firmly believe that Quality is never an Accident. It is always
              the Result of an intelligent effort.
              We all at Worth Engineering are Committed to providing our
              customers Consistent quality products and Conformation to
              specified Requirements by continuous Improvement in the
              effectiveness of Quality management system.
            </p>
            <div class="blockquote-custom-icon-end bg-Worth-color-1 shadow-sm"><FontAwesomeIcon icon={faQuoteLeft}  color="#FFFFFF"  />
          </div>
          </blockquote>
        </Row>

        <div className="card shadow cardKey mb-4" data-aos="fade-up">
          <div className="card-body">
            <h4 className="mb-4">Key Strengths and Capabilities</h4>
            <ul className="">
              <li className="mb-2">
                <p className="m-0 fs-3vw fw-normal">
                  Core expertise in manufacturing cutting tools for Bevel Gear
                  Generating - both straight and spiral gears.
                </p>
              </li>
              <li className="mb-2">
                <p className="m-0 fs-3vw fw-normal">
                  Dedicated team of qualified engineers with extensive
                  experience in tool engineering and supporting customers’
                  need.
                </p>
              </li>
              <li className="mb-2">
                <p className="m-0 fs-3vw fw-normal text-wrap">
                  Skilled workforce of approximately 20 individuals proficient
                  in various trades, equipped with state-of-the-art machinery
                  <br></br>for precision manufacturing.
                </p>
              </li>
            </ul>
          </div>
        </div>
      </div>

      <div className="container pt-5 my-5 text-center" data-aos="fade-up">
      <h3 className=" my-5 text-uppercase fw-bold text-center">
      Meet the Team Behind Worth Engineering
            </h3>
        <p className="text-break">
          A strong team is more than just a collection of individuals; It's a
          synergistic force fueled by shared goals and mutual support. Each
          member of Worth Engineering management team, brings unique expertise
          to the table, creating a dynamic environment <br></br> where diverse skills
          complement  and strengthen one another.
        </p>
      </div>

      <div className=" mb-5 container ">
        
        <CardGroup className="border border-0">
          <Card
            className="p-1 px-3 align-items-center text-center border border-0"
            data-aos="fade-right"
          >
            <CardImg
              src={Person4}
              className="img-fluid rounded-circle mx-3 shadow"
            ></CardImg>
            <CardBody>
              <Card.Title className="my-4">Anand Kulkarni</Card.Title>
              <Card.Text>
                <p>
                  <strong>Founder & CEO</strong>
                </p>
                <p>
                  Anand Kulkarni, is one of the founders of Worth Engineering,
                  and has been handling the company affairs since 1987.
                
                His deep technical expertise and proficiency in client
                management has helped Worth Engineering delight clients for more
                than 35+ years, and created a wide base of satisfied clients,
                across the Indian industry.</p>
              </Card.Text>
            </CardBody>
          </Card>
          <Card
            className="p-1 px-3 align-items-center text-center border border-0"
            data-aos="fade-right"
          >
            <CardImg
              src={Person1}
              className="img-fluid rounded-circle mx-3 shadow"
            ></CardImg>
            <CardBody>
              <Card.Title className="my-4">MM Kshatriya</Card.Title>
              <Card.Text>
                <p>
                  <strong>Founder & CTO</strong>
                </p>
                <p>
                  MM Kshatriya, another one of the founders, has been associated with Worth Engineering for
                  more than 35 years. His expertise in mechanical,
                  metallurgical, and measurement sciences, has been instrumental
                  in Worth Engineering producing outputs with very high
                  precision and accuracy.
                </p>
              </Card.Text>
            </CardBody>
          </Card>
          <Card
            className="p-1 px-3 align-items-center text-center border border-0"
            data-aos="fade-right"
          >
            <CardImg
              src={Person3}
              className="img-fluid rounded-circle mx-3 shadow"
            ></CardImg>
            <CardBody>
              <Card.Title className="my-4">Priygun Jain</Card.Title>
              <Card.Text>
                <p>
                  <strong>Partner & COO </strong>
                </p>
                <p>
                  Priygun Jain, has recently joined the Worth Engineering team
                  as the COO ,handling day-to-day affairs. He has prior expertise in solving complex
                  problems, optimizing solutions tailored to business needs, and
                  helping build successful teams is fueling Worth Engineering's
                  growth.{" "}
                </p>
              </Card.Text>
            </CardBody>
          </Card>
          <Card
            className="p-1 px-3 align-items-center text-center border border-0"
            data-aos="fade-right"
          >
            <CardImg
              src={Person2}
              className="img-fluid rounded-circle mx-3 shadow"
            ></CardImg>
            <CardBody>
              <Card.Title className="my-4">Sonal Jain</Card.Title>
              <Card.Text>
                <p>
                  <strong>Financial Consultant</strong>
                </p>
                <p>
                  Sonal Jain , is an external financial consultant to Worth Engineering.As a qualified CA, CS, and CPA, 
                  She helps manage the commercial activities and government compliances for Worth Engineering. 
                  Her expertise in Indian, US, and International taxation is crucial for the business.

                </p>
              </Card.Text>
            </CardBody>
          </Card>
        </CardGroup>
      </div>

      
      <div className="container my-5 text-wrap text-sm-left" data-aos="fade-up">
        <section id="clients" className="section-bg">
          <div className="container">
            <div className="section-header text-center">
            <h3 className=" mb-3 text-uppercase fw-bold ">
      Our Clients
            </h3>
              <p className="fs-4vw  mb-5">Meet a few of our long-standing clients</p>
            </div>

            <Row className=" no-gutters text-wrap text-center " data-aos="fade-up">
              <div className="col-lg-3 col-md-4 col-xs-6 my-auto">
                <div className="client-logo">
                  <Image src={BG} className="img-fluid" alt=""></Image>
                  <div className="overlayImg">
                    <span>Bharat Gears</span>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 col-md-4 col-xs-6 my-auto">
                <div className="client-logo">
                  <Image src={Eicher} className="img-fluid" alt=""></Image>
                  <div className="overlayImg">
                    <span>Eicher Motors</span>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 col-md-4 col-xs-6 my-auto">
                <div className="client-logo">
                  <Image src={tatamotors} className="img-fluid" alt=""></Image>
                  <div className="overlayImg">
                    <span>Tata Motors</span>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 col-md-4 col-xs-6 my-auto">
                <div className="client-logo">
                  <Image src={BF} className="img-fluid" alt=""></Image>
                  <div className="overlayImg">
                    <span>Bharat Forge</span>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 col-md-4 col-xs-6 my-auto">
                <div className="client-logo">
                  <Image src={AAM} className="img-fluid" alt=""></Image>
                  <div className="overlayImg">
                    <span>American Axle & Manufacturing</span>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 col-md-4 col-xs-6 my-auto">
                <div className="client-logo">
                  <Image src={GP} className="img-fluid" alt=""></Image>
                  <div className="overlayImg">
                    <span>Gears and Pinions</span>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 col-md-4 col-xs-6 my-auto">
                <div className="client-logo">
                  <Image src={AAL} className="img-fluid" alt=""></Image>
                  <div className="overlayImg">
                    <span>AAL Group Ltd</span>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 col-md-4 col-xs-6 my-auto">
                <div className="client-logo">
                  <Image src={emson} className="img-fluid" alt=""></Image>
                  <div className="overlayImg">
                    <span>The Emson Group</span>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 col-md-4 col-xs-6 my-auto">
                <div className="client-logo">
                  <Image src={PBG} className="img-fluid" alt=""></Image>
                  <div className="overlayImg">
                    <span>Punjab Bevel Gears Ltd.</span>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 col-md-4 col-xs-6 my-auto">
                <div className="client-logo">
                  <Image src={TK} className="img-fluid" alt=""></Image>
                  <div className="overlayImg">
                    <span>ThyssenKrupp AG</span>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 col-md-4 col-xs-6 my-auto">
                <div className="client-logo">
                  <Image src={GRZ} className="img-fluid" alt=""></Image>
                  <div className="overlayImg">
                    <span>The Graziano Electrified</span>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 col-md-4 col-xs-6 my-auto">
                <div className="client-logo">
                  <Image src={NRB} className="img-fluid" alt=""></Image>
                  <div className="overlayImg">
                    <span>NRB Bearing</span>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 col-md-4 col-xs-6 my-auto">
                <div className="client-logo">
                  <Image src={CAR} className="img-fluid" alt=""></Image>
                  <div className="overlayImg">
                    <span>Carraro Group</span>
                  </div>
                </div>
              </div>{" "}
              <div className="col-lg-3 col-md-4 col-xs-6 my-auto ">
                <div className="client-logo">
                  <Image src={ECH} className="img-fluid" alt=""></Image>
                  <div className="overlayImg">
                    <span>Echjay Industries Pvt Ltd.</span>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 col-md-4 col-xs-6 my-auto ">
                <div className="client-logo">
                <p className="fw-medium">& Many more...</p>
                </div>
              </div>
            </Row>
          </div>
        </section>
      </div>

      {/* <hr class="container border-3 border-top " /> */}
      <Footer />
    </div>
  );
}

export default About;
