import React, { useState } from "react";
import AOS from "aos";
import "aos/dist/aos.css"; // You can also use <link> for styles
// ..
import "./Home.css";
import Offcanvas from "react-bootstrap/Offcanvas";
// import AboutImg from "../utils/img/about-img.jpg";
import { Link } from "react-router-dom";
import Footer from "../components/Footer";
import logoUrl from "../Asset 10.png";
import HeaderColImage from "../utils/img/header-img.jpg";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Stack from "react-bootstrap/Stack";
import { CardGroup, Image } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import productrangeImage from "../utils/img/icons/product.png";
import precisionImage from "../utils/img/icons/precision.png";
import customsolImage from "../utils/img/icons/custom.png";

import BG from "../utils/img/clientLogo/BG.png";
import Eicher from "../utils/img/clientLogo/Eicher.png";
import tatamotors from "../utils/img/clientLogo/tatamotors.png";
import BF from "../utils/img/clientLogo/BF.png";
import AAM from "../utils/img/clientLogo/AAM_Logo.png";
import AAL from "../utils/img/clientLogo/AAL.png";
import GP from "../utils/img/clientLogo/gp.png";
import TK from "../utils/img/clientLogo/TK.png";
import GRZ from "../utils/img/clientLogo/GRZ.png";
import PBG from "../utils/img/clientLogo/PBG.png";
import emson from "../utils/img/clientLogo/emson_logo.png";
import NRB from "../utils/img/clientLogo/nrblogo.png";
import CAR from "../utils/img/clientLogo/Carraro.png";
import ECH from "../utils/img/clientLogo/ECH.png";

import footerImage from "../utils/img/footerimg.png";
function Home() {
  AOS.init();

  return (
    <div className="home-page">
      {/* _________________START : SECTION-1____________________ */}

      <div className="Section ">
        <header className="h-100 min-vh-100 d-flex align-items-center shadow">
          <Row className="p-0 g-0 ">
            <Col
              sm={7}
              className="HeaderCol1"
              data-aos="fade-right"
              width={"100%"}
            >
              <Stack
                gap={3}
                className="HeaderStackStyle mb-3"
                data-aos="fade-up"
               
              >
                <div className="pb-2 ">
                  <Link to="/" href="/" className=" mb-3">
                    <img
                      src={logoUrl}
                      width="300px"
                      height="200px"
                      alt="Logo"
                    />
                  </Link>
                  <h1 className="mb-0 text-black fw-bold text-align-left ">
                    Gear Mastery & Precision Cutting
                  </h1>
                </div>
                <div className=" pb-1 fs-3vw">
                  <p className=" text-black fw-normal text-align-left fs-3vw ">
                    Welcome to GearCraft: Your premier destination for precision
                    gear cutting tools and other allied solutions. Explore our
                    innovative products.
                  </p>
                </div>
                <div className="mb-3">
                  <Link to="/about">
                    <Button variant="outline-primary btn-more ">
                      More. . .
                    </Button>
                  </Link>
                </div>
              </Stack>
            </Col>
            <Col sm={5} className="" data-aos="fade-left">
              <Image
                src={HeaderColImage}
                className="img-resposive"
                width={"100%"} height={"100%"}
              ></Image>
            </Col>
          </Row>

          {/* </Container> */}
        </header>
      </div>
      <></>
      {/* _________________END : SECTION-1______________________ */}

      {/* _________________START : SECTION-2____________________ */}

      <div className="Section container mt-5 mb-5 ">
        <Stack gap={2} className="text-center">
          <div className="p-2" data-aos="slide-up">
            <h1 className="mb-0 text-black fw-bold text-align-left  ">
              Crafting Precision Cutting tools and Machining Works
            </h1>
          </div>
          <div className="p-2" data-aos="slide-up">
            <p className="fs-3vw text-black fw-normal text-align-left ">
              Established in 1987, Worth Engineering has been delighting
              customers for over 37 years with a core expert team dedicated to
              creating precise gear cutting tools and bespoke tool room
              machining requirements.
            </p>
          </div>
        </Stack>
      </div>

      {/* _________________END : SECTION-2______________________ */}

      {/* _________________START : SECTION-3____________________ */}

      <div className="Section container mt-5">
        <CardGroup className="text-center border border-1  p-5 border border-0">
          <Card className="align-items-center border-0" data-aos="slide-up">
            <Card.Img
              variant="top"
              src={precisionImage}
              className="cardImage1"
            />
            <Card.Body>
              <Card.Title>Precision Engineering</Card.Title>
              <Card.Text className="">
                Utilizing ingenious technology and deep expertise to ensure
                high-quality and accurate products.
              </Card.Text>
            </Card.Body>
          </Card>

          <Card className="align-items-center border-0" data-aos="slide-up">
            <Card.Img
              variant="top"
              src={productrangeImage}
              className="cardImage1"
            />
            <Card.Body>
              <Card.Title>Versatile Product Range</Card.Title>
              <Card.Text className="">
                Offering a diverse selection of straight and spiral bevel gear
                cutting tools and allied products to meet various industry
                needs.
              </Card.Text>
            </Card.Body>
          </Card>

          <Card className="align-items-center border-0" data-aos="slide-up">
            <Card.Img
              variant="top"
              src={customsolImage}
              className="cardImage1"
            />
            <Card.Body>
              <Card.Title>Custom Solutions</Card.Title>
              <Card.Text>
                Providing tailor-made solutions and custom tool room services to
                address unique customer requirements.
              </Card.Text>
            </Card.Body>
          </Card>
        </CardGroup>
      </div>
      {/* _________________END : SECTION-3______________________ */}

      {/* _________________START : SECTION-4____________________ */}

      
      <div className="Section SecManufactureCard  mt-5 mb-5">
        {/* <Row> */}
        <div className="container ">
          <Stack gap={2} className="text-center p-5">
            <div className="p-2" data-aos="slide-up">
              <h1 className="mb-0 text-black fw-bold   ">
                Our Products
              </h1>
            </div>
            <div className="p-2 fs-3vw text-black fw-normal text-align-left " data-aos="slide-up">
              <p className="">
                We manufacture cutting tools and bespoke tool-room products.</p><br/>
                <p> In
                <strong> Cutting tools</strong>, we have deep expertise in making straight and
                spiral bevel gear blades in High Speed Steel material and
                provide coating for the tools as needed. Apart from that, we
                make woodruff cutters and custom required special single point
                cutting tools. We also provide allied products for cutting tools
                like cutter body bolts and taper wedges. </p><br/><p>For <strong>Tool-room products</strong>,
                we make all types of gauges, carbide ball PCD pins, bore
                grinding fixtures, index plates, parallels / spaces, as per
                customer’s requirements. We achieve accuracy within a couple of
                microns as,per drawings to ensure that our customers are
                delighted. </p><br/>
                <p> Whether you're manufacturing automotive components,
                aerospace parts, or industrial machinery, trust Worth
                Engineering to deliver precision, reliability, and performance.
                &nbsp;
                <a
                  href="/Cutting Tools"
                  className="text-primary text-decoration-none"
                >
                  {" "}
                  More...
                </a>
              </p>
            </div>
          </Stack>
        </div>
      </div>
      {/* _________________END : SECTION-4______________________ */}

      {/* _________________START : SECTION-5____________________ */}

      <div className="Section  mb-5" >
        <div className="">
        
        <Stack gap={2} className="text-center ">
            <div className="p-2" >
              <h2 className="mb-5 text-black fw-bold   ">
              Meet a few of our long-standing clients
              </h2>
            </div>
            
            <div className="container-logo h-100">
              <Row>
                <div className="container-logo rounded">
                  <div className="slider">
                    <div className="logos">
                      <Image className="fab" src={BG}></Image>
                      <Image className="fab" src={AAL}></Image>
                      <Image className="fab" src={AAM}></Image>
                      <Image className="fab" src={BF}></Image>
                      <Image className="fab" src={Eicher}></Image>
                      <Image className="fab" src={tatamotors}></Image>
                    </div>
                    <div className="logos">
                      <Image className="fab" src={ECH}></Image>
                      <Image className="fab" src={CAR}></Image>
                      <Image className="fab" src={GP}></Image>
                      <Image className="fab" src={GRZ}></Image>
                      <Image className="fab" src={PBG}></Image>
                      <Image className="fab" src={TK}></Image>
                    </div>
                  </div>
                </div>
              </Row>
            </div>
          </Stack>
        </div>
      </div>
      {/* _________________END : SECTION-5______________________ */}

      {/* _________________START : SECTION-6____________________ */}

      <div className="Section bg-white text-light ">
        <Image
          src={footerImage}
          className="img-resposive"
          width={"100%"}
        ></Image>
      </div>
      {/* _________________END : SECTION-6______________________ */}

      {/* _________________START : SECTION-7____________________ */}

      <Footer />

      {/* _________________END : SECTION-7______________________ */}
    </div>
  );
}

export default Home;
