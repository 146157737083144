import React, { useRef, useState } from "react";
// import ReactDOM from 'react-dom'
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
// import Button from 'react-bootstrap/Button';
import Card from "react-bootstrap/Card";
import "../components/ContactForm.css";
import { Stack } from "react-bootstrap";
import { Link } from "react-router-dom";

const ContactForm = () => {
  return (
    <Stack gap={1} className="text-center align-items-center w-100 h-100">
       {/* <div className="p-2 w-100 text-center" >
       <Link to="https://mail.google.com/mail/?view=cm&to=sales@worthengg.com&su=Enqury Mail&body=Hello Admin"
          target="_blank" className="text-decoration-none"><Button className="fw-medium feedback w-50" >Contact Us</Button></Link>
       </div> */}
      <div className=" w-75 h-100 py-5">
        <Card className="ContactForm p-4 border border-0 justify-content-center w-100  h-100">
        <iframe
               src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3784.5062917950754!2d73.81365177409826!3d18.460712271002127!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bc2956835cd253b%3A0x3f0df887c287ad2f!2sWorth%20Engg!5e0!3m2!1sen!2sin!4v1713168344200!5m2!1sen!2sin"
           height={"100%"}
           width={"auto"}
              allowFullScreen=""
              aria-hidden="false"
              tabIndex="0"
            />
        </Card>
      </div>
    </Stack>
  );
};

export default ContactForm;
