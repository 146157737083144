import React, { useState } from "react";
import "../pages/Products.css";
import AOS from "aos";
import "aos/dist/aos.css";
import Footer from "../components/Footer";
import Products1 from "./Products1";
import products from "../components/ProductData";

function Products({Akey,Tkey}) {
  // const Akey ={Akey};
  console.log(Akey);
  const [activeTab, setActiveTab] = useState(Akey);

  const toggle = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };
  AOS.init();
  // ---------------------------------------------------------------------------
  const productClass = products[0].productClass;

  return (
    <div className="home-page product-page">
    <header className="mt-5">
      <div className=" h-75 d-flex align-items-center justify-content-center  flex-column">
        <h3 className="text-light mt-5">{products[0].heading}</h3>
        <a href="/Custom-made Tools" className="fs-3vw fw-medium text-white text-nowrap product-header-link " >Custom-made Tool Room Products</a>
      </div>
    </header>
    
    <div className="container my-5 text-wrap text-sm-left" data-aos="fade-up">
      <Products1 ProductData={products} Akey={Akey} Tkey={Tkey}/>
      
      </div>
      <Footer/>
    </div>
  );
}

export default Products;
