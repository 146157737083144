import "./App.css";
import { Link, Routes, Route } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";
import Home from "./pages/Home";
import About from "./pages/About";
import Products from "./pages/Products";
import ProductsCust from "./pages/ProductsCust";
import Contact from "./pages/Contact";
import logoUrl from "./Asset 10.png";
import {Image} from "react-bootstrap";

function App() {


  return (
    <div>
      <Navbar expand="lg" className="fixed-top bg-white shadow">
        <Container>
          <Navbar.Brand className="p-0 ">
            <Link to="/" href="/">
              <Image src={logoUrl} width="100%" height="80px" alt="Logo" ></Image>
            </Link>
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="me-auto justify-content-around w-100">
              <Nav.Link href="/" className="active text-uppercase">
                Home
              </Nav.Link>
              <Nav.Link href="/about" className="active text-uppercase">
                About
              </Nav.Link>
          
              <NavDropdown
                title="Products"
                id="basic-nav-dropdown"
                className="text-uppercase"
              >
                <NavDropdown.Item href="/Cutting Tools">
                  Cutting Tools
                </NavDropdown.Item>

                <NavDropdown.Divider />
                <NavDropdown.Item href="/Custom-made Tools">
                  Custom-made Tool Room Products
                </NavDropdown.Item>
              </NavDropdown>

              <Nav.Link href="/contact" className="text-uppercase">
                Contact
              </Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>

      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/Cutting Tools" element={<Products Akey='1' Tkey='1'/>} />
        <Route path="/Custom-made Tools" element={<ProductsCust/>}/>
        <Route path="/Gleason" element={<Products Akey='2' Tkey='1'/>}/>
        <Route path="/Spiromatic" element={<Products Akey='2' Tkey='4'/>}/>
        <Route path="/Kurvex" element={<Products Akey='2' Tkey='3'/>}/>
        <Route path="/CBD" element={<ProductsCust Akey='2' Tkey='1'/>}/>
        <Route path="/IndexPlate" element={<ProductsCust Akey='4' Tkey='1'/>}/>
        
        <Route path="/about" element={<About />} />
          <Route path="/contact" element={<Contact />} />
      </Routes>
      <div id="mybutton">
        <Link
          className="feedback"
          to="https://mail.google.com/mail/?view=cm&to=sales@worthengg.com&su=Enqury Mail&body=Hello Admin"
          target="_blank" 
        >
          Contact
        </Link>
      </div>
      
      <footer className="bg-body-tertiary">
       <p className=" p-3 m-0 text-center"> <span style={{"font-size":"1vw"}}>
       Worth Engineering estd. 1987. All Rights Reserved @2024
        </span></p>
      </footer>
    </div>
  );
}

export default App;
