import React from "react";
import { Link } from "react-router-dom";
import "../components/ContactInfo.css";
import whatsapp from "../utils/img/icons/WhatsAppButtonGreenSmall.png";
import { Col, Image, Row } from "react-bootstrap";
import whats from "../utils/img/contact/whatsapp.png";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEnvelope,
  faLocationDot,
  faAddressCard,
  faPhone,
} from "@fortawesome/free-solid-svg-icons";

// const element = <FontAwesomeIcon icon="fa-kit fa-my-icon" />

export function ContactInfo() {
  return (
    <div className="ContactInfo container pt-5 mb-5">
      {/* <br/>  href="https://wa.me/+919404949013"*/}
      <div className="contact-margin-start">
        <Row>
          <h4>Let's get in touch</h4>
        </Row>
        <br />
        <Row>
          <Col
            xs={{ span: 2 }}
            md={{ span: 1 }}
            className="align-items-right justify-content-right"
          >
            <FontAwesomeIcon icon={faLocationDot} color="#00356A" />
          </Col>
          <Col xs={{ span: 2 }} md={{ span: 10 }} className="text-nowrap mt-6-resp ">
            <div className="">
              {/* <h5></h5> */}
              <p>
                <strong>Worth Engineering</strong>
              </p>
              <p>64/5, Bhide Baug, Sinhagad Road,</p>
              <p> Vadgaon Budruk, Pune-411041.</p>
            </div>
          </Col>
        </Row>
        
        <Row className="my-2">
          <Col
            xs={{ span: 2 }}
            md={{ span: 1 }}
            className="align-items-right justify-content-right"
          >
            <FontAwesomeIcon icon={faEnvelope} color="#00356A" />
          </Col>
          <Col xs={{ span: 2 }} md={{ span: 10 }} className="text-nowrap mt-6-resp ">
            <div className="">
              {/* <h5></h5> */}
              <p>
              <strong> <a href="https://mail.google.com/mail/?view=cm&to=sales@worthengg.com&su=Enqury Mail&body=Hello Admin"  target="_blank" className=" text-dark">sales@worthengg.com</a></strong>
              </p>
             
            </div>
          </Col>
        </Row>
      
        <Row className="mb-2">
          <Col xs={{ span: 2 }} md={{ span: 1 }}>
            <FontAwesomeIcon icon={faAddressCard} color="#00356A" />
          </Col>
          <Col xs={{ span: 4 }} md={{ span: 3 } } className="mt-6-resp">
            <div className="text-nowrap text-align-right">
              {/* <h5></h5> */}
              <p>
                <strong>Anand Kulkarni</strong>
              </p>
            </div>
          </Col>
          <Col
            xs={{ span: 6 }}
            md={{ span: 8 }}
            className="text-nowrap align-items-left "
          >
            
            <Row className="mb-2 align-items-baseline">
              <Col xs={{ span: 12 }} md={{ span: 5 }}>
               
                <p>
                <a
                    className="text-decoration-none text-dark"
                    href="tel:+91 9822024514"
                    target="_blank"
                  >
                   
                    <strong>+91 9822024514</strong>
                  </a>
                </p>
              </Col>
              <Col xs={{ span: 1 }} md={{ span: 1 }}>
               
       <a href="sms:+919822024514"  target="_blank">     <FontAwesomeIcon icon={faEnvelope} color="#00356A" /></a>  

              </Col>
              <Col xs={{ span: 1 }} md={{ span: 1 }}>
              <a
                  className="text-decoration-none"
                  href="https://wa.me/+919822024514"
                  target="_blank"
                >
                 
                  <Image src={whats} width={"19px"} style={{verticalAlign: "top"}}/>
                </a>
              </Col>
              <Col xs={{ span: 1 }} md={{ span: 1 }}>
              
               <a
               className="text-decoration-none"
               href="tel:+91 9822024514"
               target="_blank"
             >
              
               <FontAwesomeIcon icon={faPhone} color="#00356A" />
             </a>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row className="">
          <Col xs={{ span: 2 }} md={{ span: 1 }}>
            <FontAwesomeIcon icon={faAddressCard} color="#00356A" />
          </Col>
          <Col
            xs={{ span: 4 }}
            md={{ span: 3 }}
            className="text-nowrap align-items-left mt-6-resp "
          >
            {/* <div className="text-nowrap text-align-right"> */}
            {/* <h5></h5> */}
            <p>
              <strong>Priygun Jain</strong>
            </p>
            {/* </div> */}
          </Col>
          <Col
            xs={{ span: 6 }}
            md={{ span: 8 }}
            className="text-nowrap align-items-left "
          >
            <Row className="mb-2 align-items-baseline">
              <Col xs={{ span: 12 }} md={{ span: 5 }}>
               
                <p>
                  <a
                    className="text-decoration-none text-dark"
                    href="tel:+91 9404949013"
                    target="_blank"
                  >
                   
                    <strong>+91 9404949013 </strong>
                  </a>
                </p>
              </Col>
              <Col xs={{ span: 1 }} md={{ span: 1 }}>
               
              <a href="sms:+919404949013"  target="_blank">     <FontAwesomeIcon icon={faEnvelope} color="#00356A" /></a>

              </Col>
              <Col xs={{ span: 1 }} md={{ span: 1 }}>
                <a
                  className="text-decoration-none"
                  href="https://wa.me/+919404949013"
                  target="_blank"
                >
                 
                  <Image src={whats} width={"19px"} style={{verticalAlign: "top"}}/>
                </a>
              </Col>
              <Col xs={{ span: 1 }} md={{ span: 1 }}>
              <a
                  className="text-decoration-none"
                  href="tel:+91 9404949013"
                  target="_blank"
                >
                 
                  <FontAwesomeIcon icon={faPhone} color="#00356A"  />
                </a>
              </Col>
            </Row>

            <Row className="align-items-baseline">
              <Col xs={{ span: 12 }} md={{ span: 5 }}>
               
                <p>
                 
                  <strong> +1 551 344 5482 </strong>
                </p>
              </Col>
              {/* <Col sm={2}> <FontAwesomeIcon icon={faPhone} color="#00356A" /></Col> */}
              <Col xs={{ span: 1 }} md={{ span: 1 }}>
               
               <a href="sms:+15513445482"  target="_blank"> <FontAwesomeIcon icon={faEnvelope} color="#00356A" /></a>
                
              </Col>
              <Col xs={{ span: 1 }} md={{ span: 1 }}>
              <Image src={whats} width={"19px"} style={{verticalAlign: "top"}}/>
              </Col>
              <Col xs={{ span: 1 }} md={{ span: 1 }}></Col>
            </Row>
          </Col>
        </Row>
      </div>
    </div>
  );
}
