import React from 'react';
import './Contact.css';
import { Footer } from '../components/Footer';

import { Row,Col } from 'react-bootstrap';
import AOS from "aos";

import "aos/dist/aos.css";
function Contact() {
    AOS.init();
    return (
        <div className='contact-page' >
            <br/>
            <header className='mt-5'>
                <div className='container h-100 d-flex align-items-center justify-content-center'>
                    <h1 className='text-light'>Contact</h1>
                </div>
            </header>

          <Footer/>
{/* 
            <div className='bg-dark text-light py-5'>
                <Reviews />
            </div> */}
        </div>
    )
}

export default Contact;