import React, { useState } from "react";
import {
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  Row,
  Col,
} from "reactstrap";
import "../pages/Products.css";

import { Image } from "react-bootstrap";
import classnames from "classnames";

const Tabs = ({ props, Tkey }) => {
  const [activeTab, setActiveTab] = useState(Tkey);

  const toggle = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  return (
    <div>
      <Nav tabs>
        {props.map((p) => (
          <NavItem>
            <NavLink
              className={[
                classnames({ active: activeTab === p.index }),
                " navCustomTab fw-medium ",
              ].join("")}
              onClick={() => {
                toggle(p.index);
              }}
            >
              {p.tittle}
            </NavLink>
          </NavItem>
        ))}
      </Nav>
      <TabContent activeTab={activeTab}>
        {props.map((p) => (
          <TabPane tabId={p.index} className="p-5">
             <Row className="w-auto">
              
                <Col>
                  <h4 className="mb-3">{p.tittle}</h4>
                  <p className="tabContent fw-normal">{p.p1}</p>
                  <p className="tabContent fw-normal">{p.p2}</p>
                  <p className="tabContent fw-normal">{p.p3}</p>
                </Col>
                
              </Row>
          
          </TabPane>
        ))}
      </TabContent>
    </div>
  );
};

export default Tabs;
