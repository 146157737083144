import React from "react";
import ContactForm from "../components/ContactForm";
import { ContactInfo } from "../components/ContactInfo";
// import
import {
 
  Row,
  Col,
} from "react-bootstrap";

export function Footer() {
    return (
        <div >
          <div className="Section SecManufactureCard pt-5 shadow">
        <div >
          <Row>
          {/* <Col md={1}></Col> */}
            <Col xs={{ span: 12}} md={{ span: 6}} className="pl-5-resp" >
              <ContactInfo />
            </Col>
            <Col xs={{ span: 12}} md={{ span: 6}} >
              <ContactForm />
            </Col>
          </Row>
        </div>
      </div>
        </div>
    )
};

export default Footer;
